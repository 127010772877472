@import 'colors.scss';
.landing {
    position: relative;
    width: 100%;
    height: 200vh;
    background:  $background-color;
    color: $light-color;
}

.header {
position: fixed;
top: 1rem;
left: 50%;
z-index: 2;
transform: translateX(-50%);
&__wrap {
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 60ww;
}
&__left {
    display: flex;
    align-items: center;
    gap: 2rem;
  & li {
    font-size: 18px;
    font-weight: 700;
  }
}
&__right button {
  display: flex;
  margin-left: 2rem;
  background-color: red;
  gap: 2rem;
  &:hover {
    cursor: pointer;
  }
} 
}


.image_container {
  position:relative;
  z-index: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  visibility: hidden;
  margin-bottom: 50px;
}

.image {
  max-width: 60%;
}
.size {
  max-width: 60%;
}

#modal {
  background-color: aqua; 
  visibility: visible;
   
}
