@import 'colors.scss';
.loading {
   position: fixed;
   top: 0;
   left: 0;
   right: 0;
   bottom: 0;
   width: 100%; 
   z-index: 1;
   display: grid;
   background: $background-color;
   place-items: center;
   font-family: 'Orbitron', sans-serif;
   &__with_animation {
     animation: with_animation 1s forwards;
     @keyframes with_animation {
       0% {
        width: 85%;
       }
       100%{
        width: 280px;
       }
     }

   }

    &__box {
       position: relative;
       width: 500px;
       height: 200px;
       border: 3px solid $medium-color;
       border-top: 2px solid $dark-color;
       border-bottom: 2px solid $dark-color;
       @media (max-width: 600px ){
        & {
            width: 90vw;
        }
       }
    }
    &__bar {
        width: 90%;
        height: 10px;
        position: absolute;
        top: 50%;
        left:50%;
        transform: translateX(-50%);
        background: #ccc;
        border-radius: 2px;

      &--inner {
        height:100%;
        width: 0%;
        border-radius: 2px;
        background: $medium-color;
      }

    }
  &__text {
    position:relative;
    color: white;
    padding: 1rem;
    font-size: 20px;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 355px ){
        & {
            font-size: 14px;
        }
       }
    &--O {
        width: 15px;
        height: 15px;
        margin: 0 3px;
        border-radius: 50%;
        background: white;
        animation: pulse 1s infinite;
        @keyframes pulse {
            from {
              opacity: 0;
              background: $medium-color;
            } to {
              opacity: 1;
            }
        }
    }
    &--border {
        width: 85%;
        height: 1px;
        background: $dark-color;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
    }
  }
  &__counter {
    position: absolute;
    top: 70%;
    left:0;
    color: white;
    font-size: 20px;
    font-weight: 700;
    width:100%;
    display: flex;
    align-items: center;
    justify-content: space-between ;
    padding:  10px;
    &--number {
        color: $medium-color;
    }
  }

 &__image {
   position: absolute;
   top: 50%;
   left: 50%;
   transform: translate(-50% , -50%);
   width:80%;
   opacity: 0;
 }

}