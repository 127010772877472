
.coolLink {

   position: relative;
   display: grid;
   place-items: center;
   overflow: hidden;
   &:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 3px;
    background: white;
    transform: scale3d(0,1,1);
    transform-origin: left;
    transition: transform .4s ease-in-out;

   }
   &:after {
    content: attr(data-text);
    position: absolute;
    height: 100%;
    top: 0;
    transform: translate3d(150%,0,0);
   }
   & span {
    transition: transform .4s ease-in-out;
   }
   &:hover:before {
    transform: scale3d(1,1,1);
    transform-origin: left;
   }
   &:hover:after {
    transform: translate3d(0,0,0);
    transition: transform .4s ease-in-out;
   }
   &:hover span {
    transform: translate3d(-150%,0,0);
   }

}
